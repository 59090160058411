import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "./../MDButton/index";
import { useSnackbar } from "notistack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { changeInvoiceStatus } from "../../services/invoice";

export default function InvoiceStatusModal({
  open,
  setOpen,
  selectedInvoice,
  handleUpdate,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(null);
  const handleChange = (e) => {
    setStatus(e?.target?.value);
  };

  const handleConfirm = async () => {
    await changeInvoiceStatus({
      status,
      enqueueSnackbar,
      handleModal: (bool) => setOpen(bool),
      id: selectedInvoice,
      handleUpdate,
    });
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title">Change invoice status</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Mark invoice as...
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={status}
            onChange={handleChange}
          >
            <FormControlLabel
              value="paid"
              control={<Radio />}
              label={
                <div>
                  <h3>Paid</h3>
                  <br />
                  <span style={{ color: "#7b809a" }}>
                    Payment was collected outside of Stripe.
                  </span>
                </div>
              }
            />
            <FormControlLabel
              value="void"
              control={<Radio />}
              label={
                <div>
                  <h3>Void</h3>
                  <br />
                  <span style={{ color: "#7b809a" }}>
                    This invoice was accidentally finalised or contains a
                    mistake.
                  </span>
                </div>
              }
            />
            <FormControlLabel
              value="uncollectible"
              control={<Radio />}
              label={
                <div>
                  <h3>Uncollectible</h3>
                  <br />
                  <span style={{ color: "#7b809a" }}>
                    Payment of this invoice is not expected.
                  </span>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => setOpen(false)} color="dark">
          Cancel
        </MDButton>
        <MDButton
          onClick={() => handleConfirm()}
          color="info"
          disabled={!status}
        >
          Confirm
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
