import { API } from "aws-amplify";

export const changeInvoiceStatus = async ({
  status,
  id,
  enqueueSnackbar,
  handleModal,
  handleUpdate,
}) => {
  try {
    let apiName = "productsApi";
    let path = "";
    switch (status) {
      case "paid":
        path = "/package/pay_invoice/" + id;
        break;
      case "void":
        path = "/package/void_invoice/" + id;
        break;
      default:
        path = "/package/uncollectible_invoice/" + id;
    }
    await API.get(apiName, path).then((res) => {
      enqueueSnackbar("Invoice's status updated successfully !", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
      handleModal(false);
      handleUpdate();
    });
  } catch (err) {
    console.log(err);
    enqueueSnackbar(
      err?.message || "An error occured when updating invoice's status",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      }
    );
  }
};
