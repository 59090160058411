import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import generator from "generate-password";
import { useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDInput from "components/MDInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InputAdornment from "@mui/material/InputAdornment";
import MDButton from "components/MDButton";
import { resetUserPassword } from "services/users";

const resetPasswordValidation = Yup.object().shape({
  password: Yup.string().required("This field is required"),
});

const ResetPasswordModal = ({ email, propsOpen, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: resetPasswordValidation,
    onSubmit: async (values) => {
      await resetUserPassword({
        email,
        password: values.password,
        enqueueSnackbar,
        handleClose,
      });
    },
  });
  React.useEffect(() => {
    formik.resetForm();
  }, [propsOpen]);
  const generatePassword = () => {
    const password = generator.generate({
      length: 8,
      numbers: true,
    });
    formik.setFieldValue("password", password);
  };
  return (
    <div>
      <Dialog
        open={propsOpen}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Reset password for : "<strong>{email}</strong>"
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter the new password : ({" "}
            <span
              onClick={generatePassword}
              style={{
                color: "blue",
                cursor: "pointer",
              }}
            >
              {" "}
              or generate automatically
            </span>
            )
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <MDInput
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="text"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    text={formik.values.password}
                    onCopy={(text) => {
                      enqueueSnackbar(
                        "Password copied successfully ! Click confirm to confirm reset",
                        {
                          variant: "info",
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                          autoHideDuration: 3000,
                        }
                      );
                    }}
                  >
                    <InputAdornment position="end">
                      <MDButton aria-label="copy password" color="light">
                        Copy
                      </MDButton>
                    </InputAdornment>
                  </CopyToClipboard>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => handleClose(false)} color="dark" autoFocus>
            Cancel
          </MDButton>
          <MDButton onClick={() => formik.handleSubmit()} color="info">
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResetPasswordModal;
