/* eslint-disable no-lone-blocks */
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Row from "../components/Custom/Row";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { getOneTimeProducts } from "services/one_time_prodcuts";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import OneTimeProductModal from "../components/Custom/OnTimeProduct/OneTimeProdcutModal";
import Navbar from "../components/Custom/Navbar";
import Item from "components/MuiCustom/Item";
import Container from "../components/MuiCustom/Container";
import OneTimeProductShare from "components/Custom/OneTimeProductModal";
import FindCustomers from "components/Custom/Customer/FindCustomer";
import { Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  page: {
    justifyContent: "center",
    padding: "15px",
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
  root: {
    flexGrow: 1,
    height: "70vh",
    overflow: "hidden",
    width: "100%",
  },
}));

const Header = ({ text, textAlign }) => (
  <MDBox lineHeight={1} textAlign={textAlign || "left"}>
    <MDTypography
      display="block"
      variant="button"
      color="text"
      fontWeight="medium"
    >
      {text}
    </MDTypography>
  </MDBox>
);

function OneTimeProducts() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [pages, setPages] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [openShare, setopenShare] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const [openInvoice, setOpenInvoice] = useState(false);

  const [products, setProducts] = useState([]);

  const handleChange = (v) => {
    if (v) {
      setPage(page + 1);
      setNext(true);
    } else {
      setPage(page - 1);
      setNext(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenShare = () => {
    setopenShare(true);
  };

  useEffect(() => {
    if (page !== 1) {
      if (next) {
        {
          setBody({
            limit: 10,
            starting_after: products[products?.length - 1]?.priceId,
          });
        }
      } else {
        setBody({ limit: 10, ending_before: products[0]?.priceId });
      }
    } else {
      setBody({ limit: 10 });
    }
  }, [page, next]);
  useEffect(() => {
    async function fetchAll() {
      const { products_, hasMore_, pages_ } = await getOneTimeProducts({
        handleLoading: setLoading,
        enqueueSnackbar: enqueueSnackbar,
        body,
        Pages: pages,
        page: page,
      });
      setProducts(products_);
      setHasMore(hasMore_);
      if (next) setPages(pages_);
    }

    if (body) fetchAll();
  }, [body]);

  const clearData = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <Navbar
        text="One Time Prodcuts"
        handleShare={handleOpen}
        disableShare={selectedItems.length === 0}
        handleRefresh={() => setBody({ ...body, refresh: true })}
      >
        <Tooltip title="Genrate invoice">
          <IconButton
            onClick={() => setOpenInvoice(true)}
            aria-controls="notification-menu"
            variant="contained"
            disabled={selectedItems.length === 0}
          >
            <Icon>description</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Add one time product">
          <IconButton
            onClick={handleOpen}
            aria-controls="notification-menu"
            variant="contained"
          >
            <Icon>add</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => setBody({ ...body, refresh: true })}
          >
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title="Share Package">
          <span>
            <IconButton
              color="info"
              aria-controls="notification-menu"
              variant="contained"
              disabled={selectedItems.length === 0}
              onClick={handleOpenShare}
            >
              <Icon>share</Icon>
            </IconButton>
          </span>
        </Tooltip>
      </Navbar>

      <FindCustomers
        setOpen={setOpenInvoice}
        open={openInvoice}
        selectedProducts={selectedItems}
        clearData={clearData}
      />
      <OneTimeProductModal
        handleClose={handleClose}
        refresh={() => setBody({ ...body, refresh: true })}
        open={open}
      />
      <OneTimeProductShare
        selectedItems={selectedItems}
        handleSelectedItems={setSelectedItems}
        open={openShare}
        handleOpen={setopenShare}
      />

      <MDBox pb={3}>
        <Container spacing={6}>
          <Item xs={12}>
            <Card style={{ minHeight: "80vh" }}>
              <MDBox pt={2}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ padding: "0px 10px" }}
                >
                  <Grid item xs={5}>
                    <Header text="Label" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header text="Price" textAlign="center" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header text="Updated At" textAlign="center" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header text="Quantity" textAlign="center" />
                  </Grid>
                  <Grid item xs={1} style={{ paddingRight: "18px" }}>
                    <Header text="Actions" textAlign="right" />
                  </Grid>
                </Grid>

                {!loading ? (
                  products?.length > 0 ? (
                    products?.map((_) => (
                      <Row
                        product={_}
                        key={_.id}
                        handleOpen={handleOpenShare}
                        selectedItems={selectedItems}
                        handleSelectedItems={setSelectedItems}
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        left: "calc(50% - 65px) ",
                        top: "200px",
                      }}
                    >
                      No item found
                    </div>
                  )
                ) : (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "48%",
                      color: "#3C96EF",
                    }}
                  />
                )}
                <Grid container className={classes.page}>
                  <Grid item>
                    <IconButton
                      onClick={() => handleChange(false)}
                      disabled={page === 1}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handleChange(true)}
                      disabled={hasMore === false}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {/* <Pagination
                  className={classes.page}
                  count={pages}
                  page={page}
                  size="small"
                  onChange={handleChange}
                /> */}
              </MDBox>
            </Card>
          </Item>
        </Container>
      </MDBox>
    </DashboardLayout>
  );
}

export default OneTimeProducts;
