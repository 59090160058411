import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { currencyFormat } from "utils/config";

const InvoiceReview = ({ Products, Tax, Discount }) => {
  const [tax, setTax] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  useEffect(() => {
    if (Tax) setTaxPercentage(Tax?.percentage);
  }, [Tax]);
  useEffect(() => {
    if (Discount) {
      setDiscountPercentage(Discount?.percent_off);
      setDiscountAmount(Discount?.amount_off / 100);
    }
  }, [Discount]);
  useEffect(() => {
    if (Products) {
      const tempTotal = Products?.reduce(
        (a, c) =>
          a + Number.parseFloat((c.product?.unit_amount * c.quantity) / 100),
        0
      );
      setSubtotal(tempTotal);
    }
  }, [Products]);
  useEffect(() => {
    if (subtotal) {
      discountPercentage
        ? setDiscount(subtotal * (discountPercentage / 100))
        : setDiscount(discountAmount);
    }
  }, [subtotal, discountPercentage]);
  useEffect(() => {
    setTax((taxPercentage / 100) * (subtotal - discount));
  }, [subtotal, discount, taxPercentage]);
  useEffect(() => {
    setTotal(subtotal + tax - discount);
  }, [tax, subtotal, discount]);

  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        style={{ borderTop: "2px solid rgb(0,0,0,0.2)" }}
      >
        <Grid item xs={4}>
          <MDTypography fontWeight="medium">Summe</MDTypography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <MDTypography fontWeight="medium">
            {"€ " + currencyFormat(subtotal || 0)}
          </MDTypography>
        </Grid>
      </Grid>
      {(discountPercentage > 0 || discountAmount > 0) && (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item xs={4}>
            <MDTypography fontWeight="medium">{`Discount ${
              discountPercentage > 0 ? `(${discountPercentage + "%"})` : ""
            }`}</MDTypography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <MDTypography fontWeight="medium">
              {"-€ " + currencyFormat(discount)}
            </MDTypography>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item xs={4}>
          <MDTypography
            fontWeight="medium"
            variant="h5"
          >{`USt. (${taxPercentage}%)`}</MDTypography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <MDTypography fontWeight="medium">
            {" "}
            {"€ " + currencyFormat(tax)}
          </MDTypography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item xs={4}>
          <MDTypography variant="h4" fontWeight="bold">
            Gesamtsumme
          </MDTypography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <MDTypography fontWeight="bold" variant="h4">
            {"€ " + currencyFormat(total)}
          </MDTypography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceReview;
