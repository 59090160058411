import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "App";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { SnackbarProvider } from "notistack";

import { MaterialUIControllerProvider } from "context";

// Soft UI Context Provider
import Payment from "./pages/Payment";
import SignIn from "layouts/authentication/sign-in";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import ChangePassword from "pages/ChangePassword";
Amplify.configure(awsExports);

ReactDOM.render(
  <MaterialUIControllerProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider max={3}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/payment/:id" element={<Payment />} />
            <Route exact path="/change_password" element={<ChangePassword />} />

            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </MaterialUIControllerProvider>,
  document.getElementById("root")
);
