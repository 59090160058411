import { Icon, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import userIcon from "../../../assets/images/user.png";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { deleteCustomer } from "../../../services/customers";
import { useSnackbar } from "notistack";
import DeleteCustomerModal from "./DeleteCustomerModal";
import EditCustomer from "./EditCustomerModal";

export const Author = ({ image, name, type }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);
const Customer = ({ user, handleRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <DeleteCustomerModal
        open={openDialog}
        setOpen={setOpenDialog}
        cus={user?.name}
        handleDelete={() => {
          deleteCustomer({
            id: user?.id,
            enqueueSnackbar,
            handleModal: setOpenDialog,
            handleRefresh,
          });
        }}
      />
      <EditCustomer
        open={openEdit}
        setOpen={setOpenEdit}
        customer={user}
        handleRefresh={handleRefresh}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ borderTop: "1px solid #F0F2F5", padding: "10px" }}
      >
        <Grid item xs={2}>
          <Author image={userIcon} name={user?.metadata?.name || "NA"} />
        </Grid>

        <Grid item xs={2}>
          <MDBox lineHeight={1} textAlign="center">
            <MDTypography display="block" variant="button" fontWeight="medium">
              {user?.email}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={1}>
          <MDBox lineHeight={1} textAlign="center">
            <MDTypography display="block" variant="button" fontWeight="medium">
              {user?.invoice_settings?.default_payment_method ? "Yes" : "No"}
            </MDTypography>
          </MDBox>
        </Grid>

        <Grid item xs={1}>
          <MDBox lineHeight={1} textAlign="center">
            <MDTypography
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {moment.unix(user?.created).format("DD-MM-YYYY , HH:mm")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={1} align="right">
          <MDBox lineHeight={1}>
            <IconButton
              aria-controls="notification-menu"
              variant="contained"
              onClick={() => setOpenEdit(true)}
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              aria-controls="notification-menu"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              <Icon>delete</Icon>
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default Customer;
