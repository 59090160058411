import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { AppBar, Dialog, ListItemButton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { getTaxes, getCoupons } from "services/recurring_products";
import MDButton from "components/MDButton";
import MDInput from "../MDInput/index";
import Container from "../MuiCustom/Container";
import Item from "../MuiCustom/Item";
import { currencyFormat } from "utils/config";
const useStyles = makeStyles((theme) => ({
  page: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
  root: {
    "& .Mui-selected": {
      background: "rgb(59, 149, 238,1)",
      color: "white !important",
      "&:hover": {
        color: "black !important",
      },
    },

    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    height: "70vh",
    overflow: "hidden",
    width: "100%",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} style={{ height: "100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductModal = ({
  selectedItems,
  handleSelectedItems,
  open,
  handleOpen,
}) => {
  const classes = useStyles();
  const [taxRates, setTaxRates] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [selectedTax, setSelectedTax] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [iteration, setIteration] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const url =
    window.location.href.replace("recurring_products", "") + "payment/";
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    async function fetch() {
      let taxes = await getTaxes(enqueueSnackbar);
      let coupons = await getCoupons(enqueueSnackbar);
      setTaxRates(taxes);
      setCoupons(coupons);
    }
    fetch();
  }, []);
  const handleClose = () => {
    handleOpen(false);
    setValue(0);
    setIteration(1);
    setSelectedCoupon("");
    setSelectedTax("");
  };

  const handleListItemClick = (value) => {
    setSelectedTax(value?.id);
  };

  const handleIndex = (event, newValue) => {
    setValue(newValue);
  };
  const headerStyle = {
    padding: "1%",
    textAlign: "center",
    border: "2px solid #3B95EE ",
    borderTopRadius: "5px",
    fontWeight: "bold",
  };
  const itemStyle = {
    textAlign: "center",
    padding: "2%",
    borderLeft: "1px solid #3B95EE ",
    borderRight: "1px solid #3B95EE ",
    borderBottom: "1px solid #3B95EE ",
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            aria-label="simple tabs example"
            variant="fullWidth"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <Tab label="Choose tax" {...a11yProps(0)} />
            <Tab label="Choose discount" {...a11yProps(1)} />
            <Tab label="Iterations" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} style={{ height: "100%" }}>
          <Container style={headerStyle}>
            <Item xs={4}>Name</Item>
            <Item xs={4}>Country</Item>
            <Item xs={4}>Percentage</Item>
          </Container>
          <List
            style={{
              height: "90%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {taxRates?.map((tax) => (
              <ListItemButton
                selected={selectedTax === tax.id}
                onClick={(e) => {
                  handleListItemClick(tax);
                  handleIndex(e, 1);
                }}
                key={tax.id}
                style={itemStyle}
              >
                <ListItemText
                  style={{ width: "5%" }}
                  primary={tax.display_name}
                />
                <ListItemText primary={tax.country} />
                <ListItemText primary={tax.percentage + "%"} />
              </ListItemButton>
            ))}
          </List>
        </TabPanel>
        <TabPanel value={value} index={1} style={{ height: "100%" }}>
          <Grid container style={headerStyle}>
            <Grid item xs={6}>
              Name
            </Grid>
            <Grid item xs={6}>
              Discount
            </Grid>
          </Grid>
          <List
            style={{
              height: "83%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {coupons.map((tax) => (
              <ListItem
                selected={selectedCoupon === tax.id}
                button
                onClick={() => {
                  setValue(2);
                  setSelectedCoupon(tax.id);
                }}
                key={tax.id}
                style={itemStyle}
              >
                <ListItemText style={{ width: "3%" }} primary={tax.name} />
                <ListItemText
                  primary={
                    tax.percent_off
                      ? tax.percent_off + "%"
                      : `€ ${currencyFormat(tax.amount_off / 100)}`
                  }
                />
              </ListItem>
            ))}
          </List>
          <Grid
            container
            style={{
              position: "absolute",
              bottom: 5,
              width: "95%",
              textAlign: "center",
            }}
          >
            <Grid item xs>
              <MDButton
                variant="contained"
                color="dark"
                style={{ width: "90%" }}
                onClick={() => {
                  setValue(0);
                }}
              >
                Back
              </MDButton>
            </Grid>
            <Grid item xs>
              <MDButton
                variant="contained"
                color="info"
                style={{ width: "90%" }}
                onClick={() => {
                  setValue(2);
                }}
              >
                skip
              </MDButton>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          style={{
            height: "100%",
            textAlign: "center",
            borderBottom: "3px solid rgb(0,0,0,0.2) ",
            fontWeight: "bold",
          }}
        >
          <Grid
            container
            direction="column"
            style={{ height: "80%" }}
            justifyContent="center"
          >
            <Grid item>
              <label style={{ marginRight: 20 }}>Iterations</label>
              <MDInput
                type="number"
                value={iteration}
                onClick={(e) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  e.target.value > 0
                    ? setIteration(e.target.value)
                    : setIteration(1);
                }}
              />
            </Grid>
            <Grid item>
              <p style={{ marginTop: 10 }}>
                Your subscription will last{" "}
                {Number.parseFloat(
                  selectedItems?.[0]?.product?.recurring?.interval_count *
                    iteration
                ) +
                  " " +
                  selectedItems?.[0]?.product?.recurring?.interval +
                  "(s)"}
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              position: "absolute",
              bottom: 5,
              width: "95%",
              textAlign: "center",
            }}
          >
            <Grid item xs>
              <MDButton
                variant="contained"
                color="dark"
                style={{ width: "90%" }}
                onClick={() => {
                  setValue(1);
                }}
              >
                Back
              </MDButton>
            </Grid>
            <Grid item xs>
              <CopyToClipboard
                text={
                  url +
                  selectedItems
                    ?.map((_) => `${_?.quantity}*${_?.product.id}`)
                    .join("+") +
                  "?type=recurring" +
                  "&tax=" +
                  selectedTax +
                  (selectedCoupon ? "&coupon=" + selectedCoupon : "") +
                  "&iterations=" +
                  iteration
                }
                onCopy={(text) => {
                  handleClose();
                  handleSelectedItems([]);
                  enqueueSnackbar("Link copied successfully", {
                    variant: "info",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    autoHideDuration: 3000,
                  });
                }}
              >
                <MDButton
                  variant="contained"
                  color="info"
                  style={{ width: "90%" }}
                  onClick={() => {}}
                >
                  Confirm
                </MDButton>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </Dialog>
  );
};

export default ProductModal;
