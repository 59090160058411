import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDButton from '../../MDButton/index';
export default function DeleteCustomerModal({
  open,
  setOpen,
  handleDelete,
  cus,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title">Delete Customer</DialogTitle>
      <DialogContent>
        Are you sure about deleting this customer:{" "}
        <strong style={{ color: "black" }}>{cus}</strong>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => setOpen(false)} color="dark">
          Cancel
        </MDButton>
        <MDButton onClick={handleDelete} color="info">
          Confirm
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
