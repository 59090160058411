import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import OneTimeProductForm from "./OnTimeProductForm";



export default function OneTimeProductModal({ refresh, open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ borderBottom: "2px solid gray" }}
      >
        <strong> Add Product</strong>
      </DialogTitle>
      <DialogContent>
        <OneTimeProductForm
          refresh={refresh}
          handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
