import React from "react";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { currencyFormat } from "../../utils/config";
import productIcon from "../../assets/images/product.png";
import MDTypography from "components/MDTypography";

const useStyles = makeStyles(() => ({
  card: {
    borderTop: "1px solid rgb(0,0,0,0.2)",
  },
}));

const ProductCart = ({ product, type }) => {
  const classes = useStyles();

  const interval =
    type === "recurring"
      ? product?.recurring?.interval
        ? `/${product.recurring.interval === "month" && "Monat"}`
        : ""
      : "";
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      align="center"
      className={classes.card}
    >
      <Grid item xs={1}>
        <MDTypography>{product?.quantity + " x"}</MDTypography>
      </Grid>

      <Grid item xs>
        <MDTypography>{product?.name}</MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography>
          {"€ " +
            currencyFormat(Math.round(product?.unit_amount / 100) || 0) +
            interval}
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography>
          {"€ " +
            currencyFormat(
              Math.round((product?.unit_amount * product?.quantity) / 100) || 0
            ) +
            interval}
        </MDTypography>
      </Grid>
    </Grid>
  );
};

export default ProductCart;
