import React from "react";
import Grid from "@mui/material/Grid";

function Item(props) {
  return (
    <Grid {...props} item sx={{ mb: 1 }}>
      {props.children}
    </Grid>
  );
}

export default Item;
