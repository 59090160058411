import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "../layouts/authentication/components/BasicLayout/index";
import Card from "@mui/material/Card";
import MDBox from "../components/MDBox/index";
import MDTypography from "../components/MDTypography/index";
import MDInput from "../components/MDInput/index";
import MDButton from "../components/MDButton/index";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";

const loginValidationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      await Auth.signIn(location.state.email, location.state.password)
        .then(async (user) => {
          await Auth.completeNewPassword(user, values.password).then((res) => {
            enqueueSnackbar("Password changed successfully", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              autoHideDuration: 3000,
            });
            localStorage.setItem(
              "token",
              res?.signInUserSession?.accessToken.jwtToken
            );
            history("/");
          });
        })

        .catch((err) => {
          enqueueSnackbar(err.message || "An error occured", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            autoHideDuration: 3000,
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.message || "An error occured", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            autoHideDuration: 3000,
          });
        });
    },
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            MWF Payment Portal
          </MDTypography>
          <MDTypography variant="body2" fontWeight="medium" mt={1}>
            Please change your password in order to continue
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={visible ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setVisible(!visible)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                type={visible ? "text" : "password"}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setVisible(!visible)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                // disabled={loading}
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
              >
                Confirm
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default ChangePassword;
