import { IbanElement } from "@stripe/react-stripe-js";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography/index";
import MDInput from "components/MDInput/index";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton/index";
import { countries } from "../../utils/config";

// const countries = [
//   "Belgien",
//   "Bulgarien",
//   "Dänemark",
//   "Deutschland",
//   "Estland",
//   "Finnland",
//   "Frankreich",
//   "Griechenland",
//   "Irland",
//   "Italien",
//   "Kroatien",
//   "Lettland",
//   "Litauen",
//   "Luxemburg",
//   "Malta",
//   "Niederlande",
//   "Österreich",
//   "Polen",
//   "Portugal",
//   "Rumänien",
//   "Scheweden",
//   "Slowakei",
//   "Slowenien",
//   "Spanien",
//   "Tschechien",
//   "Ungarn",
//   "Zypern",
// ];
const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "18px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  placeholderCountry: "DE",
  style: IBAN_STYLE,
};

const IbanForm = ({
  onSubmit,
  loading,
  disabled,
  handleChange,
  name,
  company,
  address,
  zip,
  country,
  line2,
  city,
  email,
}) => {
  return (
    <form className="form" onSubmit={onSubmit}>
      <MDTypography variant="h3">SEPA Lastschriftmandat</MDTypography>
      <Grid
        container
        direction="column"
        spacing={2}
        style={{
          marginTop: "10px",
        }}
      >
        <Grid item>
          <MDInput
            value={company}
            onChange={(e) => handleChange(e, "company")}
            name="company"
            type="company"
            placeholder="Musterfirma"
            label="Firma"
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          {/* // switching name and company label to make sure company is put as a name instead of name */}
          <MDInput
            value={name}
            onChange={(e) => handleChange(e, "name")}
            name="accountholder-name"
            placeholder="Max Mustermann"
            required
            fullWidth
            label="Name"
          />
        </Grid>
        <Grid item>
          <MDInput
            value={address}
            onChange={(e) => handleChange(e, "address")}
            name="address"
            type="address"
            placeholder="Straße, Hausnummer"
            required
            label="Adresse"
            fullWidth
          />
        </Grid>
        <Grid item>
          <MDInput
            value={line2}
            onChange={(e) => handleChange(e, "line2")}
            name="line2"
            type="address"
            placeholder="Straße, Hausnummer"
            label="Adresse Line 2"
            fullWidth
          />
        </Grid>

        <Grid item>
          <MDInput
            value={zip}
            onChange={(e) => handleChange(e, "zip")}
            name="zip"
            type="number"
            placeholder="12345"
            required
            label="Postleitzahl"
            fullWidth
          />
        </Grid>
        <Grid item>
          <MDInput
            value={city}
            onChange={(e) => handleChange(e, "city")}
            name="city"
            type="address"
            placeholder="Stadt"
            required
            label="Stadt"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Autocomplete
            name="country"
            label="Country"
            type="text"
            value={country}
            onChange={(e, v) => handleChange(v, "country")}
            getOptionLabel={(option) =>
              option ? option?.name + " (" + option?.id + ")" : ""
            }
            fullWidth
            id="country"
            disablePortal
            options={countries}
            required
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        </Grid>
        <Grid item>
          <MDInput
            value={email}
            onChange={(e) => handleChange(e, "email")}
            name="email"
            type="email"
            placeholder="max.musterman@example.de"
            required
            label="Email"
            fullWidth
          />
        </Grid>

        <Grid item>
          <MDTypography variant="h4" textAlign="center">
            IBAN
          </MDTypography>
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </Grid>
        <Grid item align="center">
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <MDButton
              color="info"
              fullWidth
              className={
                loading || disabled
                  ? "payment-button disabled"
                  : "payment-button"
              }
              type="submit"
              disabled={loading || disabled}
            >
              SEPA Mandat Freigabe
            </MDButton>
          )}
        </Grid>
        <Grid item>
          <MDTypography
            variant="caption"
            fontWeight="bold"
            style={{ color: "#343a40" }}
          >
            Indem Sie Ihre Zahlungsinformationen und Bankdetails angeben und
            diese Zahlung bestätigen, ermächtigen Sie (A) Make Work Flow GmbH
            und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank
            zur Belastung Ihres Kontos und (B) an Ihre Bank zur Belastung Ihres
            Kontos gemäß diesen Anweisungen zu senden. Als Teil Ihrer Rechte
            haben Sie gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank
            Anspruch auf eine Rückerstattung von Ihrer Bank. Eine Rückerstattung
            muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos
            geltend gemacht werden. Sie erklären sich damit einverstanden,
            Benachrichtigungen über zukünftige Abbuchungen bis zu 2 Tage vor
            deren Ausführung zu erhalten.
          </MDTypography>
        </Grid>
      </Grid>
    </form>
  );
};

export default IbanForm;
