import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import MDButton from "components/MDButton";
import Container from "../../MuiCustom/Container";
import Item from "../../MuiCustom/Item";
import { createCustomer } from "../../../services/customers";
import { Autocomplete } from "@mui/material";
import { countries } from "../../../utils/config";
const addCustomerValidation = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  accountEmail: Yup.string().required("This field is required"),
  company: Yup.string().required("This field is required"),
  country: Yup.object()
    .required("This field is required")
    .nullable("This field is required"),
  address1: Yup.string().required("This field is required"),
  postalCode: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  taxId: Yup.string().required("This field is required"),
});
export default function CustomerModal({ open, setOpen, handleUpdate }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      accountEmail: "",
      company: "",
      billingEmail: "",
      country: "",
      address1: "",
      address2: "",
      state: "",
      postalCode: "",
      city: "",
      taxId: "",
      billingAsAccount: true,
    },
    validationSchema: addCustomerValidation,
    onSubmit: async (values) => {
      try {
        const body = {
          name: values.company,
          email: values.accountEmail,
          address: {
            country: values.country?.id,
            city: values.postalCode + " " + values.city,
            line1: values.address1 + " " + values.address2,
            state: values?.state,
          },
          metadata: {
            name: values?.name,
            country: values?.country?.id,
            line1: values?.address1,
            line2: values?.address2,
            postal_code: values?.postalCode,
            city: values?.city,
            tax: values.taxId,
          },
          tax_id_data: [
            {
              type: "eu_vat",
              value: values.taxId,
            },
          ],
        };
        const { error, data } = await createCustomer({
          handleLoading: (val) => setLoading(val),
          body,
          enqueueSnackbar,
        });
        if (error == null) {
          setOpen(false);
          formik.resetForm();
          handleUpdate();
        }
      } catch (err) {
        enqueueSnackbar(err?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        });
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>
      <DialogContent style={{ padding: "2%" }}>
        <form onSubmit={formik.handleSubmit}>
          <Container direction="column" spacing={1}>
            <Item>
              <TextField
                autoFocus
                name="name"
                label="Name"
                placeholder="Jane Smith"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                required
              />
            </Item>
            <Item>
              <TextField
                name="accountEmail"
                label="Account Email"
                placeholder="jane@example.com"
                type="accountEmail"
                value={formik.values.accountEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.accountEmail &&
                  Boolean(formik.errors.accountEmail)
                }
                helperText={
                  formik.touched.accountEmail && formik.errors.accountEmail
                }
                fullWidth
                required
              />
            </Item>
            <Item>
              <TextField
                name="company"
                label="Company"
                type="text"
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
                fullWidth
                required
              />
            </Item>
            {/* <Item>
              <TextField
                name="billingEmail"
                label="Billing Email "
                type="text"
                value={formik.values.billingEmail}
                disabled={checked}
                onChange={formik.handleChange}
                error={
                  formik.touched.billingEmail &&
                  Boolean(formik.errors.billingEmail)
                }
                helperText={
                  formik.touched.billingEmail && formik.errors.billingEmail
                }
                fullWidth
              />
            </Item>
            <Item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    name="checkedEmail"
                  />
                }
                label="Same as account email"
              />
            </Item> */}
            <Item>
              <Autocomplete
                name="country"
                label="Country"
                type="text"
                value={formik.values.country}
                onChange={(e, v) => formik.setFieldValue("country", v)}
                getOptionLabel={(option) =>
                  option ? option?.name + " (" + option?.id + ")" : ""
                }
                fullWidth
                id="country"
                disablePortal
                options={countries}
                required
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    error={Boolean(
                      formik.errors.country && formik.touched.country
                    )}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
            </Item>
            <Item>
              <TextField
                name="address1"
                label="Address line 1"
                type="text"
                value={formik.values.address1}
                onChange={formik.handleChange}
                error={
                  formik.touched.address1 && Boolean(formik.errors.address1)
                }
                helperText={formik.touched.address1 && formik.errors.address1}
                fullWidth
                required
              />
            </Item>
            <Item>
              <TextField
                name="address2"
                label="Address line 2"
                type="text"
                value={formik.values.address2}
                onChange={formik.handleChange}
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={formik.touched.address2 && formik.errors.address2}
                fullWidth
              />
            </Item>
            <Item>
              <TextField
                name="postalCode"
                label="Postal Code"
                type="text"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                }
                fullWidth
                required
              />
            </Item>
            <Item>
              <TextField
                name="city"
                label="City"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                fullWidth
                required
              />
            </Item>
            <Item>
              <TextField
                name="state"
                label="State"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                fullWidth
              />
            </Item>
            <Item>
              <TextField
                name="taxId"
                label="Tax ID"
                type="text"
                value={formik.values.taxId}
                onChange={formik.handleChange}
                error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                helperText={formik.touched.taxId && formik.errors.taxId}
                fullWidth
                required
              />
            </Item>

            <Container justifyContent="flex-end" style={{ marginTop: "20px" }}>
              <MDButton
                onClick={() => setOpen(false)}
                color="dark"
                style={{ marginRight: "20px" }}
              >
                Cancel
              </MDButton>
              <MDButton type="submit" color="info">
                Submit
              </MDButton>
            </Container>
          </Container>
        </form>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={() => {
            console.log("hello", formik.values);
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}
