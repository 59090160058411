/* eslint-disable no-lone-blocks */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Navbar from "../components/Custom/Navbar";
import { getInvoices } from "services/one_time_prodcuts";
import Invoice from "../components/Custom/Invoice";
import InvoiceStatusModal from "../components/Custom/InvoiceStatusModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  page: {
    justifyContent: "center",
    padding: "15px",
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
  root: {
    flexGrow: 1,
    height: "70vh",
    overflow: "hidden",
    width: "100%",
  },
}));

const Header = ({ text, textAlign }) => (
  <MDBox lineHeight={1} textAlign={textAlign || "left"}>
    <MDTypography
      display="block"
      variant="button"
      color="text"
      fontWeight="medium"
    >
      {text}
    </MDTypography>
  </MDBox>
);

function Invoices() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [statusModal, setStatusModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pages, setPages] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleChange = (v) => {
    if (v) {
      setPage(page + 1);
      setNext(true);
    } else {
      setPage(page - 1);
      setNext(false);
    }
  };

  useEffect(() => {
    if (page !== 1) {
      if (next) {
        {
          setBody({
            limit: 11,
            starting_after: invoices[invoices?.length - 1]?.id,
          });
        }
      } else {
        setBody({ limit: 11, ending_before: invoices[0]?.id });
      }
    } else {
      setBody({ limit: 11 });
    }
  }, [page, next]);
  useEffect(() => {
    async function fetchAll() {
      const { invoices_, hasMore_, pages_ } = await getInvoices({
        handleLoading: setLoading,
        enqueueSnackbar: enqueueSnackbar,
        body,
        Pages: pages,
        page,
      });
      setInvoices(invoices_);
      setHasMore(hasMore_);
      if (next) setPages(pages_);
    }

    if (body) fetchAll();
  }, [body]);

  return (
    <DashboardLayout>
      <InvoiceStatusModal
        open={statusModal}
        setOpen={setStatusModal}
        selectedInvoice={selectedInvoice}
        handleUpdate={() => setBody({ ...body, refresh: true })}
      />
      <Navbar text="Invoices">
        <Tooltip title="Refresh">
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => setBody({ ...body, refresh: true })}
          >
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>
      </Navbar>

      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card style={{ minHeight: "80vh" }}>
              <MDBox pt={2}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ padding: "0px 10px" }}
                >
                  <Grid item xs={1}>
                    <Header text="Amount" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header textAlign="center" text="Status" />
                  </Grid>
                  <Grid item xs={2}>
                    <Header text="Invoice number" textAlign="center" />
                  </Grid>
                  <Grid item xs={3}>
                    <Header text="Customer email" textAlign="center" />
                  </Grid>
                  <Grid item xs={2}>
                    <Header text="Customer name" textAlign="center" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header textAlign="center" text="Due date" />
                  </Grid>
                  <Grid item xs={1}>
                    <Header textAlign="center" text="Created at" />
                  </Grid>
                </Grid>

                {!loading ? (
                  invoices?.length ? (
                    invoices?.map((_) => (
                      <Invoice
                        item={_}
                        key={_.id}
                        handleStatus={setStatusModal}
                        handleSelectedInvoice={setSelectedInvoice}
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        left: "calc(50% - 65px) ",
                        top: "200px",
                      }}
                    >
                      No invoice found
                    </div>
                  )
                ) : (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "48%",
                      color: "#3C96EF",
                    }}
                  />
                )}
                <Grid container className={classes.page}>
                  <Grid item>
                    <IconButton
                      onClick={() => handleChange(false)}
                      disabled={page === 1}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handleChange(true)}
                      disabled={hasMore === false}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {/* <Pagination
                  count={pages}
                  page={page}
                  size="small"
                  onChange={handleChange}
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Invoices;
