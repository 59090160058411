import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Elements } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import { useParams, useLocation } from "react-router-dom";
import PaymentSetupForm from "./PaymentSetupForm";
import { currencyFormat } from "utils/config";
import Card from "@mui/material/Card";
import PageLayout from "../examples/LayoutContainers/PageLayout/index";
import { getProductsById } from "services/payment";
import ProductCart from "components/Custom/ProductCart";
import { couponsById, taxById } from "services/payment";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { paymentIntent } from "services/payment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
// const stripePromise = loadStripe(
//   "pk_test_51JEwa0Khn2QUE9KaX1ByOU5oTTzO177RoRe8YgGAd9uUTYnaYXvWsZe5sbnVTvc99JFofgpcyMETbcPh8cLTU5Ke00PWZ0wWZp"
// );
const stripePromise = loadStripe(
  "pk_live_51JEwa0Khn2QUE9KaNazD6ax39xaSQdFzIdRwOwrUO8aIk986XMv7qzSOkuJowZfrJxUugw9K8R4PY4E0inGc5WZo00q7ZvraSH"
);
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,

    height: "99vh",
    overflow: "hidden",
  },
  left: {
    background: "yellow",
  },
}));
const Payment = () => {
  const classes = useStyles();
  const [clientSecret, setClientSecret] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const { id } = useParams();
  const query = useQuery();
  const [tax, setTax] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const queryTax = query.get("tax");
  const queryCoupon = query.get("coupon");
  const type = query.get("type");
  useEffect(() => {
    const fetchProducts = async () => {
      let ids = id?.split("+");
      let idsObj = {};
      ids.forEach((currentId) => {
        const splittedId = currentId.split("*");
        const quantity = splittedId[0];
        const idStr = splittedId[1];
        idsObj = { ...idsObj, [idStr]: quantity };
      });

      if (!ids) {
        return;
      }
      const { products } = await getProductsById({
        ids,
        enqueueSnackbar,
        handleLoading: setLoading,
      });
      setProducts(
        products?.map((item, index) => ({
          ...item,
          quantity: idsObj[item["id"]],
        }))
      );
    };
    if (id) fetchProducts();
  }, [id]);
  useEffect(() => {
    const fetchCustomer = async () => {
      let tempTotal = products?.reduce(
        (a, c) => a + Number.parseFloat((c.unit_amount * c.quantity) / 100),
        0
      );

      setSubtotal(tempTotal);

      const { clientSecret } = await paymentIntent({
        amount: tempTotal * 100,
        type,
      });
      setClientSecret(clientSecret);
    };
    if (products.length > 0) fetchCustomer();
  }, [products]);

  useEffect(() => {
    const fetchCoupon = async () => {
      let { discountPercentage, discountAmount } = await couponsById({
        id: queryCoupon,
      });
      if (discountPercentage) {
        setDiscountPercentage(discountPercentage);
      } else {
        setDiscountAmount(discountAmount);
      }
      setDiscount(
        discountAmount
          ? discountAmount / 100
          : (subtotal * discountPercentage) / 100
      );
    };
    if (queryCoupon && subtotal) {
      fetchCoupon();
    }
  }, [queryCoupon, subtotal]);
  useEffect(() => {
    const fetchTax = async () => {
      const { taxPercentage } = await taxById({ id: queryTax });
      setTaxPercentage(taxPercentage);
    };
    if (queryTax) {
      fetchTax();
    }
  }, [queryTax]);
  useEffect(() => {
    setTax((taxPercentage / 100) * (subtotal - discount));
  }, [subtotal, discount, taxPercentage]);
  useEffect(() => {
    setTotal(subtotal + tax - discount);
  }, [tax]);

  return (
    <PageLayout background="light">
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={7}>
          <Card className={classes.left}>
            {!loading ? (
              <Grid
                container
                direction="column"
                justifyContent="flex-end"
                style={{
                  padding: "1rem",
                  height: "90vh",
                }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  style={{ height: "80%" }}
                >
                  <Grid item>
                    <MDTypography variant="h3">Übersicht</MDTypography>
                  </Grid>
                  <Grid item container direction="column">
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      align="center"
                    >
                      <Grid item xs={1}>
                        <MDTypography fontWeight="medium" color="dark">
                          Betragen
                        </MDTypography>
                      </Grid>
                      <Grid item xs>
                        <MDTypography fontWeight="medium">
                          Produktbezeichnung
                        </MDTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <MDTypography fontWeight="medium">
                          Stückpreis
                        </MDTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <MDTypography fontWeight="medium">
                          Gesamtpreis
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ flex: 1, overflow: "auto" }}>
                    {products.map((_) => (
                      <ProductCart type={type} key={_.id} product={_} />
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ height: "18%" }}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ borderTop: "2px solid rgb(0,0,0,0.2)" }}
                  >
                    <Grid item xs={2}>
                      <MDTypography fontWeight="medium">Summe</MDTypography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "end" }}>
                      <MDTypography fontWeight="medium">
                        {"€ " + currencyFormat(subtotal || 0)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                  {(discountPercentage > 0 || discountAmount > 0) && (
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Grid item xs={2}>
                        <MDTypography fontWeight="medium">{`Discount ${
                          discountPercentage > 0
                            ? `(${discountPercentage + "%"})`
                            : ""
                        }`}</MDTypography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "end" }}>
                        <MDTypography fontWeight="medium">
                          {"-€ " + currencyFormat(discount)}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={2}>
                      <MDTypography
                        fontWeight="medium"
                        variant="h5"
                      >{`USt. (${taxPercentage}%)`}</MDTypography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "end" }}>
                      <MDTypography fontWeight="medium">
                        {" "}
                        {"€ " + currencyFormat(tax)}
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={2}>
                      <MDTypography variant="h4" fontWeight="bold">
                        Gesamtsumme
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "end" }}>
                      <MDTypography fontWeight="bold" variant="h4">
                        {"€ " + currencyFormat(total)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "48%",
                  color: "#3C96EF",
                }}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            style={{
              padding: "1rem",
              height: "90vh",
              overflowY: "auto",
            }}
          >
            <Elements stripe={stripePromise}>
              <PaymentSetupForm
                type={type}
                prices={(products ?? []).map((_) => ({
                  price: _.priceId,
                  quantity: _.quantity,
                }))}
                tax={query.get("tax")}
                coupon={query.get("coupon")}
                iterations={query.get("iterations")}
                clientSecret={clientSecret}
                prodId={id}
              />
            </Elements>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Payment;
