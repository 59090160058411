import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import MDButton from "components/MDButton";
import { createUser } from "services/users";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("This field should be an email")
    .required("This field is required"),
  password: Yup.string().required("This field is required"),
  pass: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const AddUserModal = ({ propsOpen, handleClose, handleRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [visible, setVisible] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: { password: "", pass: "", email: "" },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      await createUser({
        email: values.email,
        password: values.password,
        enqueueSnackbar,
        handleClose,
      }).then(() => {
        handleClose(false);
        handleRefresh();
      });
    },
  });
  React.useEffect(() => {
    formik.resetForm();
  }, [propsOpen]);

  return (
    <div>
      <Dialog
        open={propsOpen}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add user</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email"
              required
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="password"
              label="Password"
              type={visible ? "test" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisible(!visible)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="pass"
              required
              label="Confirm password"
              type={visible ? "text" : "password"}
              value={formik.values.pass}
              onChange={formik.handleChange}
              error={formik.touched.pass && Boolean(formik.errors.pass)}
              helperText={formik.touched.pass && formik.errors.pass}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisible(!visible)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <MDTypography variant="caption" color="text">
              The user will be able to see all products and generate links for
              potential clients ! You can reset user password at any time
            </MDTypography>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => handleClose(false)} color="dark" autoFocus>
            Cancel
          </MDButton>
          <MDButton onClick={() => formik.handleSubmit()} color="info">
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUserModal;
