import { IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import productIcon from "../../assets/images/product.png";
import moment from "moment";
import Quantity from "components/Custom/Quantity";
import Checkbox from "@mui/material/Checkbox";
import ShareIcon from "@mui/icons-material/Share";
import Grid from "@mui/material/Grid";

const allStatus = [
  { id: "draft", color: "light" },
  { id: "open", color: "info" },
  { id: "paid", color: "success" },
  { id: "uncollectible", color: "secondary" },
];

const Invoice = ({ item, handleStatus, handleSelectedInvoice }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      style={{ borderTop: "1px solid #F0F2F5", padding: "10px" }}
    >
      <Grid item xs={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {"€ " + (Number.parseFloat(item?.total / 100).toFixed(2) || 0)}
        </MDTypography>
      </Grid>
      <Grid item xs={1} textAlign="center">
        <MDBadge
          badgeContent={item?.status}
          color={allStatus.find((_) => item?.status === _?.id)?.color}
          style={{
            cursor:
              item?.status !== "paid" && item?.status !== "draft" && "pointer",
          }}
          variant="gradient"
          size="md"
          onClick={() => {
            if (item?.status !== "paid" && item?.status !== "draft") {
              handleSelectedInvoice(item?.id);
              handleStatus(true);
            }
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <MDBox textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.number || "----"}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={3}>
        <MDBox textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.customer_email}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={2}>
        <MDBox textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.customer_name}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={1}>
        <MDBox textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.due_date
              ? moment.unix(item?.due_date).format("DD-MM-YYYY")
              : "--"}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={1}>
        <MDBox textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.created
              ? moment.unix(item?.created).format("DD-MM-YYYY,HH:mm")
              : "--"}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default Invoice;
