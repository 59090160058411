import { IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import productIcon from "../../assets/images/product.png";
import moment from "moment";
import Quantity from "components/Custom/Quantity";
import Checkbox from "@mui/material/Checkbox";
import ShareIcon from "@mui/icons-material/Share";
import Grid from "@mui/material/Grid";

const Author = ({ image, name, type }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={type}
          color="success"
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  </MDBox>
);
const Row = ({ product, selectedItems, handleSelectedItems, handleOpen }) => {
  const [quantity, setQuantity] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let selected = selectedItems?.filter(
      (_) => _.product.id === product.id
    ).length;
    setChecked(selected ? true : false);
    if (!selected) setQuantity(0);
  }, [selectedItems]);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setQuantity(1);
    } else {
      setQuantity(0);
    }
  };
  useEffect(() => {
    if (quantity > 1) {
      setChecked(true);
      let newItems = selectedItems.map((_) => {
        if (_?.product.id === product?.id) {
          return { ..._, quantity };
        } else {
          return _;
        }
      });
      handleSelectedItems(newItems);
    } else if (quantity === 1) {
      let selected = selectedItems?.filter(
        (_) => _.product.id === product.id
      ).length;
      if (selected) {
        let newItems = selectedItems.map((_) => {
          if (_?.product.id === product?.id) {
            return { ..._, quantity };
          } else {
            return _;
          }
        });
        handleSelectedItems(newItems);
      } else {
        handleSelectedItems([...selectedItems, { product, quantity }]);
      }
      setChecked(true);
    } else {
      setChecked(false);
      let newItems = selectedItems.filter((_) => _?.product.id !== product?.id);
      handleSelectedItems(newItems);
    }
  }, [quantity]);
  const handleQuantity = (value) => {
    if (value > 0) {
      setQuantity(value);
    } else {
      setQuantity(0);
    }
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      style={{ borderTop: "1px solid #F0F2F5", padding: "10px" }}
    >
      <Grid item xs={5}>
        <Author image={productIcon} name={product?.name} type={product?.type} />
      </Grid>
      <Grid item xs={1}>
        <MDBox lineHeight={1} textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {"€ " +
              (Number.parseFloat(product?.unit_amount / 100).toFixed(2) || 0)}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={1}>
        <MDBox lineHeight={1} textAlign="center">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {" " + moment.unix(product?.updated).format("DD-MM-YYYY,HH:mm")}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={1} textAlign="right">
        <Quantity quantity={quantity} handleQuantity={handleQuantity} />
      </Grid>
      <Grid item xs={1} alignItems="flex-end" textAlign="right">
        <IconButton
          onClick={() => {
            if (!quantity) setQuantity(1);
            handleOpen();
          }}
        >
          <ShareIcon />
        </IconButton>
        <Checkbox checked={checked} onChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export default Row;
