import { API } from "aws-amplify";

export const getProdcuts = async ({
  handleLoading,
  enqueueSnackbar,
  body,
  Pages,
  page,
}) => {
  let products = [];
  let hasMore = false;
  let pages = Pages;
  handleLoading(true);
  await API.post("productsApi", "/products", { body: body })
    .then(async (res) => {
      handleLoading(false);
      products = res?.products?.newArray;
      hasMore = res?.products?.has_more;
      if (res?.products?.has_more && !res?.products?.refresh) {
        pages = page + 1;
      }
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });
  return {
    products_: products,
    hasMore_: hasMore,
    pages_: pages,
  };
};

export const getTaxes = async (enqueueSnackbar) => {
  let taxes = [];
  await API.get("productsApi", "/package/taxes")
    .then((res) => {
      if (res.data) {
        taxes = res?.data;
      } else {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        });
      }
    })
    .catch((e) =>
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      })
    );
  return taxes;
};

export const getCoupons = async (enqueueSnackbar) => {
  let coupons = [];
  await API.get("productsApi", "/package/coupons")
    .then((res) => {
      if (res.data) {
        coupons = res?.data ?? [];
      } else {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        });
      }
    })
    .catch((e) => {
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });
  return coupons;
};
