import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "../../MDButton/index";
import { Chip } from "@mui/material";
import { genrateInvoice } from "../../../services/one_time_prodcuts";
import { useSnackbar } from "notistack";
import Divider from "@mui/material/Divider";
import InvoiceReview from "../InvoiceReview";
import MDInput from "components/MDInput";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ConfirmInvoice({
  open,
  setOpen,
  cus,
  products,
  handleCloseModal,
  tax,
  discount,
  clearData,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [dueModal, setDueModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dueDays, setDueDays] = useState(0);

  const handleConfirm = async () => {
    const body = {
      products: products?.map((_) => ({
        price: _?.product?.priceId,
        quantity: _?.quantity,
      })),
      customer: cus?.id,
      options: {
        collection_method: "send_invoice",
        days_until_due: dueDays || 0,
        default_tax_rates: [tax?.id],
        discounts: [{ coupon: discount?.id }],
      },
    };
    const { error, data } = await genrateInvoice({
      handleLoading: setLoading,
      body,
      enqueueSnackbar,
    });
    if (error === null && data !== null) {
      clearData();
      setDueModal(false);
      setOpen(false);
      handleCloseModal(false);
    }
  };
  const handleDueDaysChange = (e) => {
    const { value } = e?.target;
    value >= 0 ? setDueDays(value) : setDueDays(0);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Review invoice</DialogTitle>
        <DialogContent>
          You will be generating an invoice for the customer :{" "}
          <strong style={{ color: "black" }}>{cus?.name}</strong>
          <br />
          <Divider variant="middle" />
          Products included :
          {products?.map((_) => (
            <Chip
              label={_?.quantity + "x    " + _?.product.name}
              style={{ marginRight: "10px", marginBottom: "10px" }}
            />
          ))}
          <br />
          {tax && (
            <>
              <Divider variant="middle" />
              Applied TAX :{" "}
              <Chip
                label={tax?.display_name + " (" + tax?.percentage + "%)"}
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
            </>
          )}
          <br />
          {discount && (
            <>
              <Divider textAlign="right"></Divider>
              Applied Discount :{" "}
              <Chip
                label={
                  discount.percent_off
                    ? discount?.name + " (" + discount?.percent_off + "%)"
                    : discount?.name + " €" + discount?.amount_off / 100
                }
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
            </>
          )}
          <br />
          <InvoiceReview Products={products} Tax={tax} Discount={discount} />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpen(false)} color="dark">
            Cancel
          </MDButton>
          <MDButton onClick={() => setDueModal(true)} color="info">
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dueModal}
        onClose={() => setDueModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Confirm invoice</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ marginRight: "10px" }}> Days until due : </span>
          <MDInput
            value={dueDays}
            onChange={handleDueDaysChange}
            name="dueDays"
            type="number"
            required
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setDueModal(false)} color="dark">
            Cancel
          </MDButton>
          <LoadingButton
            variant="outlined"
            onClick={() => handleConfirm()}
            disabled={loading}
            loading={loading}
            style={{ backgroundColor: "#1A73E8" }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
