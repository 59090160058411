import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
export const getOneTimeProducts = async ({
  handleLoading,
  enqueueSnackbar,
  body,
  Pages,
  page,
}) => {
  let products = [];
  let hasMore = false;
  let pages = Pages;
  handleLoading(true);
  await API.post("productsApi", "/products/one-time-products", { body: body })
    .then(async (res) => {
      handleLoading(false);
      products = res?.products?.newArray;
      hasMore = res?.products?.has_more;
      if (res?.products?.has_more && !res?.products?.refresh) {
        pages = page + 1;
      }
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });
  return {
    products_: products,
    hasMore_: hasMore,
    pages_: pages,
  };
};
export const createOneTimeProduct = async ({
  handleLoading,
  enqueueSnackbar,
  body,
}) => {
  handleLoading(true);
  let apiName = "productsApi";
  let path = "/package/createProduct";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body,
  };

  return await API.post(apiName, path, myInit)
    .then((res) => {
      handleLoading(true);
      enqueueSnackbar(
        "Product created successfully! Please refresh to see changes",
        {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 5000,
        }
      );
      return {
        data: res,
        error: null,
      };
    })
    .catch((err) => {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
      return {
        data: null,
        err: err,
      };
    })
    .finally(() => {
      handleLoading(false);
    });
};

export const genrateInvoice = async ({
  enqueueSnackbar,
  body,
  handleLoading,
}) => {
  handleLoading(true);
  let apiName = "productsApi";
  let path = "/package/generateInvoice";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body,
  };

  return await API.post(apiName, path, myInit)
    .then((res) => {
      enqueueSnackbar("The invoice is generated succussfully !", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 5000,
      });
      return {
        data: res,
        error: null,
      };
    })
    .catch((err) => {
      enqueueSnackbar(
        err?.response?.data?.message ||
          "An error occured when genereting the invoice!",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        }
      );
      return {
        data: null,
        err: err,
      };
    })
    .finally(() => {
      handleLoading(false);
    });
};

export async function getInvoices({
  handleLoading,
  enqueueSnackbar,
  body,
  Pages,
  page,
}) {
  let invoices_ = [];
  let hasMore_ = false;
  let pages_ = Pages;
  let apiName = "productsApi";
  let path = "/package/listInvoices";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body,
  };
  handleLoading(true);
  await API.post(apiName, path, myInit)
    .then((res) => {
      invoices_ = res?.data;
      hasMore_ = res?.has_more;
      if (res?.has_more && !res?.refresh) {
        pages_ = page + 1;
      }
      handleLoading(false);
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(
        err?.response?.data?.message ||
          "An error occured when getting the invoices",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        }
      );
    });

  return {
    invoices_,
    hasMore_,
    pages_,
  };
}
