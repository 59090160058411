import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import {
  CircularProgress,
  TextField,
  Accordion,
  AccordionSummary,
  Grid,
  Button,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { createOneTimeProduct } from "services/one_time_prodcuts";
import Container from "../../MuiCustom/Container";
const addCustomerValidation = Yup.object().shape({
  name: Yup.string().required("The name is required"),
  unit_amount: Yup.number().min(1).required("The unit amount is required"),
});
const useStyles = makeStyles((theme) => ({
  form: {
    padding: "1%",
  },
  input: {
    margin: "1%",
  },
}));
export default function OneTimeProductForm({ handleClose, refresh }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: { name: "", description: "", unit_amount: 0 },
    validationSchema: addCustomerValidation,
    onSubmit: async (values) => {
      try {
        const body = {
          product: {
            name: values.name,
            description: values.description,
          },
          price: {
            currency: "eur",
            unit_amount: +values.unit_amount * 100,
          },
        };
        const { error, data } = createOneTimeProduct({
          handleLoading: (loading) => setLoading(loading),
          enqueueSnackbar,
          body,
        });
        if (error == null) {
          handleClose();
          refresh();
        }
      } catch (err) {
        enqueueSnackbar(err?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          autoHideDuration: 3000,
        });
      }
    },
  });
  if (loading)
    return (
      <CircularProgress
        style={{ width: "20%", height: "20%" }}
        color="secondary"
      />
    );
  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <TextField
        autoFocus
        className={classes.input}
        name="name"
        label="Name"
        placeholder="Ex:Television"
        type="text"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        fullWidth
      />
      <TextField
        name="description"
        className={classes.input}
        label="Description"
        type="text"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        fullWidth
        multiline={true}
        minRows={2}
      />

      <TextField
        className={classes.input}
        name="unit_amount"
        label="Unit Amount(€)"
        type="number"
        value={formik.values.unit_amount}
        onChange={formik.handleChange}
        error={formik.touched.unit_amount && Boolean(formik.errors.unit_amount)}
        helperText={formik.touched.unit_amount && formik.errors.unit_amount}
        fullWidth
      />

      <Container justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          style={{
            marginTop: "2%",
            color: "white",
          }}
          color="primary"
        >
          Create Product
        </Button>
      </Container>
    </form>
  );
}
