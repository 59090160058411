import { Auth, API } from "aws-amplify";
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";

export const client = new CognitoIdentityProviderClient({
  credentials: {
    accessKeyId: "AKIAQKTSTAT7UWKKJFMQ",
    secretAccessKey: "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",
  },
  region: "eu-central-1",
});

export async function getCustomers({
  handleLoading,
  enqueueSnackbar,
  body,
  Pages,
  page,
}) {
  let customers = [];
  let hasMore = false;
  let pages_ = Pages;
  let apiName = "productsApi";
  let path = "/package/listCustomers";
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body,
  };
  handleLoading(true);
  await API.post(apiName, path, myInit)
    .then((res) => {
      customers = res?.data;
      hasMore = res?.has_more;
      if (res?.has_more && !res?.refresh) {
        pages_ = page + 1;
      }
      handleLoading(false);
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });

  return {
    customers,
    hasMore,
    pages_,
  };
}
export async function findCustomers({
  handleLoading,
  enqueueSnackbar,
  body,
  Pages,
  page,
}) {
  let customers = [];
  let hasMore = false;
  let pages_ = Pages;
  let apiName = "productsApi";
  let path = "/package/findCustomer";
  let myInit = {
    body,
  };
  handleLoading(true);
  await API.post(apiName, path, myInit)
    .then((res) => {
      customers = res?.data;
      hasMore = res?.has_more;
      if (res?.has_more && !res?.refresh) {
        pages_ = page + 1;
      } else {
        pages_ = page;
      }
      handleLoading(false);
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });

  return {
    customers,
    hasMore,
    pages_,
  };
}

export const deleteCustomer = async ({
  id,
  enqueueSnackbar,
  handleModal,
  handleRefresh,
}) => {
  try {
    let apiName = "productsApi";
    let path = "/package/deleteCustomer/" + id;
    await API.get(apiName, path).then((res) => {
      enqueueSnackbar("The Customer has been deleted successfully !", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
      handleModal(false);
      handleRefresh();
    });
  } catch (err) {
    enqueueSnackbar(err?.message || "An error occured when deleting customer", {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      autoHideDuration: 3000,
    });
  }
};
export const createCustomer = async ({
  body,
  enqueueSnackbar,
  handleLoading,
}) => {
  try {
    let apiName = "productsApi";
    let path = "/package/createCustomer";
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body,
    };
    handleLoading(true);
    const res = await API.post(apiName, path, myInit);
    enqueueSnackbar("The Customer has been created successfully !", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      autoHideDuration: 3000,
    });
    return {
      data: res,
      error: null,
    };
  } catch (err) {
    enqueueSnackbar(
      err?.message || "An error occured while creating customer",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      }
    );
    return {
      error: err,
      data: null,
    };
  } finally {
    handleLoading(false);
  }
};
export const updateCustomer = async ({
  body,
  id,
  enqueueSnackbar,
  handleLoading,
}) => {
  try {
    let apiName = "productsApi";
    let path = "/package/updateCustomer/" + id;
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body,
    };
    handleLoading(true);
    const res = await API.put(apiName, path, myInit);
    enqueueSnackbar(
      "The Customer has been updated successfully! Refresh in few seconds to see the changes",
      {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      }
    );
    return {
      data: res,
      error: null,
    };
  } catch (err) {
    enqueueSnackbar(
      err?.message || "An error occured while creating customer",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      }
    );
    return {
      error: err,
      data: null,
    };
  } finally {
    handleLoading(false);
  }
};
