import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import User from "../components/Custom/User";
import { getUsers } from "services/users";
import Navbar from "../components/Custom/Navbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import AddUserModal from "../components/Custom/AddUserModal";

const Header = ({ text }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="button"
      color="text"
      fontWeight="medium"
    >
      {text}
    </MDTypography>
  </MDBox>
);

function Users() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [body, setBody] = useState({ limit: 10 });
  const [loading, setLoading] = useState(true);
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function fetchAll() {
      const users = await getUsers({
        handleLoading: setLoading,
        enqueueSnackbar: enqueueSnackbar,
        limit: 10,
      });
      setUsers(users);
    }

    fetchAll();
  }, [body]);

  return (
    <DashboardLayout>
      <Navbar
        text="Manage Users"
        handleShare={handleOpen}
        handleRefresh={() => setBody({ ...body, refresh: true })}
      >
        <Tooltip title="Add user">
          <IconButton
            aria-controls="notification-menu"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <Icon>add</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => setBody({ ...body, refresh: true })}
          >
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>
      </Navbar>
      <AddUserModal
        propsOpen={open}
        handleClose={handleClose}
        handleRefresh={() => setBody({ ...body, refresh: true })}
      />
      <MDBox pb={3} style={{ height: "90vh" }}>
        <Grid container spacing={6} style={{ height: "100%" }}>
          <Grid item xs={12} style={{ height: "100%" }}>
            <Card style={{ height: "100%" }}>
              <MDBox pt={2} style={{ height: "100%" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ padding: "0px 10px" }}
                >
                  <Grid item xs={5}>
                    <Header text="Email" />
                  </Grid>
                  <Grid item xs={1} style={{ paddingLeft: "10px" }}>
                    <Header text="Created At" />
                  </Grid>

                  <Grid item xs={1} style={{ paddingLeft: "30px" }}>
                    <Header text="Actions" />
                  </Grid>
                </Grid>
                <div style={{ overflow: "auto", height: "90%" }}>
                  {!loading ? (
                    users?.length > 0 ? (
                      users?.map((_) => (
                        <User user={_} key={_?.Attributes[0]?.Value} />
                      ))
                    ) : (
                      <div
                        style={{
                          display: "inline",
                          position: "relative",
                          left: "calc(50% - 64px) ",
                          top: "250px",
                          width: "129px",
                        }}
                      >
                        No user found
                      </div>
                    )
                  ) : (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "48%",
                        color: "#3C96EF",
                      }}
                    />
                  )}
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
