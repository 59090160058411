import { IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import userIcon from "../../assets/images/user.png";
import moment from "moment";
import Quantity from "components/Custom/Quantity";
import Checkbox from "@mui/material/Checkbox";
import ShareIcon from "@mui/icons-material/Share";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import ResetPasswordModal from "./ResetPasswordModal";

const Author = ({ image, name, type }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);
const User = ({ user }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => setOpenDialog(false);

  return (
    <>
      <ResetPasswordModal
        email={user.Attributes[2].Value}
        propsOpen={openDialog}
        handleClose={handleClose}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ borderTop: "1px solid #F0F2F5", padding: "10px" }}
      >
        <Grid item xs={5}>
          <Author image={userIcon} name={user.Attributes[2].Value} />
        </Grid>

        <Grid item xs={1}>
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {moment(user?.UserCreateDate).format("DD-MM-YYYY , HH:mm")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox lineHeight={1}>
            <MDButton
              variant="contained"
              color="light"
              size="small"
              onClick={() => setOpenDialog(true)}
            >
              Reset password
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default User;
