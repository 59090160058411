/* eslint-disable no-lone-blocks */
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { Author } from "./Customer";
import { findCustomers } from "services/customers";
import Item from "components/MuiCustom/Item";
import TextField from "@mui/material/TextField";
import MDButton from "../../MDButton/index";
import { Dialog, ListItemButton } from "@mui/material";
import userIcon from "../../../assets/images/user.png";
import { useFormik } from "formik";
import ConfirmInvoice from "./ConfirmInvoice";
import Box from "@mui/material/Box";
import { AppBar } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getTaxes, getCoupons } from "services/recurring_products";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Container from "../../MuiCustom/Container";
import ListItem from "@mui/material/ListItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  page: {
    justifyContent: "center",
    padding: "15px",
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
  root: {
    "& .Mui-selected": {
      background: "rgb(59, 149, 238,1)",
      color: "white !important",
      "&:hover": {
        color: "black !important",
      },
    },

    flexGrow: 1,
    minHeight: "70vh",
    overflow: "hidden",
    width: "100%",
  },
  customer: {
    borderTop: "1px solid #F0F2F5",
    padding: "8px 0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E8F0FE !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} style={{ height: "100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Header = ({ text }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="button"
      color="text"
      fontWeight="medium"
    >
      {text}
    </MDTypography>
  </MDBox>
);

function FindCustomers({ open, setOpen, selectedProducts, clearData }) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [pages, setPages] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [addModal, setaddModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [customer, setCustomer] = useState("");
  const [value, setValue] = useState(0);
  const [taxRates, setTaxRates] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [selectedTax, setSelectedTax] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleModal = (status) => setaddModal(status);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    // validationSchema: addCustomerValidation,
    onSubmit: async (values) => {
      setBody({ ...body, email: values?.email });
    },
  });

  const handleChange = (v) => {
    if (v) {
      setPage(page + 1);
      setNext(true);
    } else {
      setPage(page - 1);
      setNext(false);
    }
  };

  const handleListItemClick = (value) => {
    setSelectedTax(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    async function fetch() {
      let taxes = await getTaxes(enqueueSnackbar);
      let coupons = await getCoupons(enqueueSnackbar);
      setTaxRates(taxes);
      setCoupons(coupons);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (page !== 1) {
      if (next) {
        {
          setBody({
            limit: 10,
            starting_after: customers[customers?.length - 1]?.id,
          });
        }
      } else {
        setBody({ limit: 10, ending_before: customers[0]?.id });
      }
    } else {
      setBody({ limit: 10 });
    }
  }, [page, next]);
  useEffect(() => {
    async function fetchAll() {
      const { customers, hasMore, pages_ } = await findCustomers({
        handleLoading: setLoading,
        enqueueSnackbar: enqueueSnackbar,
        Pages: pages,
        body,
        page: page,
      });
      setCustomers(customers);
      setHasMore(hasMore);
      setPages(pages_);
    }

    if (body) fetchAll();
  }, [body]);
  useEffect(() => {
    if (customer) setValue(1);
  }, [customer]);
  const headerStyle = {
    padding: "1%",
    textAlign: "center",
    border: "2px solid #3B95EE ",
    borderTopRadius: "5px",
    fontWeight: "bold",
  };
  const itemStyle = {
    textAlign: "center",
    padding: "2%",
    borderLeft: "1px solid #3B95EE ",
    borderRight: "1px solid #3B95EE ",
    borderBottom: "1px solid #3B95EE ",
  };

  return (
    <>
      <ConfirmInvoice
        open={confirm}
        setOpen={setConfirm}
        cus={customer}
        handleCloseModal={setOpen}
        products={selectedProducts}
        tax={selectedTax}
        discount={selectedCoupon}
        clearData={clearData}
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setValue(0);
        }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              aria-label="simple tabs example"
              variant="fullWidth"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Tab label="Find customer" {...a11yProps(0)} />
              <Tab label="Choose tax" {...a11yProps(1)} />
              <Tab label="Choose discount" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} style={{ height: "100%" }}>
            <Grid container>
              <Grid item xs={12} justifyContent="space-between">
                <Item>
                  <TextField
                    name="email"
                    placeholder="Customer Email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={formik.touched.company && formik.errors.company}
                  />
                </Item>
                <MDButton
                  type="submit"
                  color="info"
                  onClick={formik.handleSubmit}
                  style={{ position: "absolute", top: "62px", right: "20px" }}
                >
                  Search
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <MDBox>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ padding: "0px 10px" }}
                  >
                    <Grid item xs={6}>
                      <Header text="Name" />
                    </Grid>
                    <Grid item xs={6}>
                      <Header text="Email" />
                    </Grid>
                  </Grid>
                  <Grid style={{ height: "550px", marginBottom: "20px" }}>
                    {customers?.length > 0 && !loading ? (
                      customers?.map((_) => (
                        <Grid
                          container
                          id={_?.id}
                          alignItems="center"
                          className={classes.customer}
                          onClick={() => {
                            setCustomer(_);
                          }}
                          style={{
                            backgroundColor:
                              customer?.id === _?.id && "#3B95EE",
                            color: customer?.id === _?.id && "white!important",
                          }}
                        >
                          <Grid item xs={6}>
                            <Author image={userIcon} name={_?.name || "NA"} />
                          </Grid>

                          <Grid item xs={6}>
                            <MDBox lineHeight={1} textAlign="left">
                              <MDTypography
                                display="block"
                                variant="button"
                                fontWeight="medium"
                              >
                                {_?.email}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "48%",
                          color: "#3C96EF",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid container className={classes.page}>
                    <Grid item>
                      <IconButton
                        onClick={() => handleChange(false)}
                        disabled={page === 1}
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleChange(true)}
                        disabled={hasMore === false}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/* <Pagination
                    className={classes.page}
                    count={pages}
                    page={page}
                    size="small"
                    onChange={handleChange}
                  /> */}
                </MDBox>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} style={{ height: "100%" }}>
            <Container style={headerStyle}>
              <Item xs={4}>Name</Item>
              <Item xs={4}>Country</Item>
              <Item xs={4}>Percentage</Item>
            </Container>
            <List
              style={{
                height: "90%",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {taxRates?.map((tax) => (
                <ListItemButton
                  selected={selectedTax?.id === tax.id}
                  onClick={(e) => {
                    handleListItemClick(tax);
                    setValue(2);
                  }}
                  key={tax.id}
                  style={itemStyle}
                >
                  <ListItemText
                    style={{ width: "5%" }}
                    primary={tax.display_name}
                  />
                  <ListItemText primary={tax.country} />
                  <ListItemText primary={tax.percentage + "%"} />
                </ListItemButton>
              ))}
            </List>
            <Grid
              container
              style={{
                position: "absolute",
                bottom: 5,
                width: "95%",
                textAlign: "center",
              }}
            >
              <Grid item xs>
                <MDButton
                  variant="contained"
                  color="dark"
                  style={{ width: "90%" }}
                  onClick={() => {
                    setValue(0);
                  }}
                >
                  Back
                </MDButton>
              </Grid>
              <Grid item xs>
                <MDButton
                  variant="contained"
                  color="info"
                  style={{ width: "90%" }}
                  onClick={() => {
                    setValue(2);
                  }}
                >
                  Confirm
                </MDButton>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2} style={{ height: "100%" }}>
            <Grid container style={headerStyle}>
              <Grid item xs={6}>
                Name
              </Grid>
              <Grid item xs={6}>
                Discount
              </Grid>
            </Grid>
            <List
              style={{
                height: "83%",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {coupons.map((coupon) => (
                <ListItem
                  selected={selectedCoupon?.id === coupon.id}
                  button
                  onClick={() => {
                    setSelectedCoupon(coupon);
                    setConfirm(true);
                  }}
                  key={coupon.id}
                  style={itemStyle}
                >
                  <ListItemText style={{ width: "3%" }} primary={coupon.name} />
                  {coupon.percent_off ? (
                    <ListItemText primary={coupon.percent_off + "%"} />
                  ) : (
                    <ListItemText primary={"€ " + coupon.amount_off / 100} />
                  )}
                </ListItem>
              ))}
            </List>
            <Grid
              container
              style={{
                position: "absolute",
                bottom: 5,
                width: "95%",
                textAlign: "center",
              }}
            >
              <Grid item xs>
                <MDButton
                  variant="contained"
                  color="dark"
                  style={{ width: "90%" }}
                  onClick={() => {
                    setValue(1);
                  }}
                >
                  Back
                </MDButton>
              </Grid>
              <Grid item xs>
                <MDButton
                  variant="contained"
                  color="info"
                  style={{ width: "90%" }}
                  onClick={() => {
                    setConfirm(true);
                  }}
                >
                  Confirm
                </MDButton>
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      </Dialog>
    </>
  );
}

export default FindCustomers;
