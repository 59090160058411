// @mui icons
import Icon from "@mui/material/Icon";
import Products from "pages/Products";
import OneTimeProducts from "./pages/OneTimeProducts";
import Users from "./pages/Users";
import Customers from "pages/Customers";
import Invoices from "pages/Invoices";

const routes = [
  {
    type: "collapse",
    name: "Recurring Products",
    key: "recurring_products",
    icon: <Icon fontSize="small">date_range</Icon>,
    route: "/recurring_products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "One Time Products",
    key: "one_time_products",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/one_time_products",
    component: <OneTimeProducts />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Manage Users",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    component: <Users />,
  },

  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/invoices",
    component: <Invoices />,
  },
];

export default routes;
