/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { API } from "aws-amplify";
import Swal from "sweetalert2";

export const paymentIntent = async ({ amount, type }) => {
  let clientSecret = "";
  try {
    const result = await API.post(
      "productsApi",
      "/package/payment_intent/" + type,
      { body: { amount: amount } }
    ).then((res) => {
      clientSecret = res?.clientSecret;
    });
  } catch (err) {
    console.log(err);
  }
  return { clientSecret };
};

export const getProductsById = async ({
  ids,
  enqueueSnackbar,
  handleLoading,
}) => {
  let products = [];

  try {
    handleLoading(true);
    await API.post("productsApi", "/package", {
      body: { ids: ids?.map((_) => _?.split("*")?.[1]) },
    }).then((res) => {
      handleLoading(false);
      products = res?.products;
    });
  } catch (err) {
    handleLoading(false);
    enqueueSnackbar(
      err?.response?.data?.message || err || "An error occured!",
      {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      }
    );
  }

  return { products };
};

export const couponsById = async ({ id }) => {
  let discountPercentage = "";
  let discountAmount = "";

  try {
    await API.post("productsApi", "/package/coupon", { body: { id } }).then(
      (res) => {
        discountPercentage = res.data?.percent_off;
        discountAmount = res.data?.amount_off;
      }
    );
  } catch (err) {
    console.log(err);
  }
  return { discountPercentage, discountAmount };
};

export const taxById = async ({ id }) => {
  let taxPercentage = "";

  try {
    await API.post("productsApi", "/package/tax", {
      body: { id },
    }).then((res) => {
      console.log(res);
      taxPercentage = res.data.percentage;
    });
  } catch (err) {}
  return { taxPercentage };
};

export const confirmSubscriptionApi = async ({ body, enqueueSnackbar }) => {
  try {
    await API.post("productsApi", "/package/create_subscription_test", {
      body,
    }).then(() => {
      Swal.fire({
        title: "Sie haben das SEPA Mandat erfolgreich abgeschlossen ! ",
        icon: "success",
      });
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        err?.message ||
        "Ein unerwarteter Fehler ist aufgetreten ! bitte versuch es später",
    });
  }
};
