import { Auth, API } from "aws-amplify";
import userContext from "context/userContext";
import {
  AdminSetUserPasswordCommand,
  CognitoIdentityProviderClient,
  AdminConfirmSignUpCommand,
  AdminAddUserToGroupCommand,
} from "@aws-sdk/client-cognito-identity-provider";

export const client = new CognitoIdentityProviderClient({
  credentials: {
    accessKeyId: "AKIAQKTSTAT7UWKKJFMQ",
    secretAccessKey: "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",
  },
  region: "eu-central-1",
});
export async function getUsers({ limit, handleLoading, enqueueSnackbar }) {
  let users = [];
  let apiName = "AdminQueries";
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: "users",
      limit: limit,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  handleLoading(true);
  const rest = await API.get(apiName, path, myInit)
    .then((res) => {
      users = res?.Users;
      handleLoading(false);
    })
    .catch((err) => {
      handleLoading(false);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });

  return users;
}

export const resetUserPassword = async ({
  email,
  password,
  enqueueSnackbar,
  handleClose,
}) => {
  try {
    const command = new AdminSetUserPasswordCommand({
      UserPoolId: "eu-central-1_1uHZjaMD6",
      Username: email,
      Password: password,
    });

    const response = await client.send(command).then((res) => {
      handleClose();
      enqueueSnackbar("Reset password succesfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      });
    });
  } catch (err) {
    enqueueSnackbar(err?.message, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      autoHideDuration: 3000,
    });
  }
};

export const createUser = async ({ enqueueSnackbar, email, password }) => {
  await Auth.signUp({
    username: email,
    password: password,
  })
    .then(async (res) => {
      const command = new AdminConfirmSignUpCommand({
        UserPoolId: "eu-central-1_1uHZjaMD6",
        Username: email,
      });
      const response = await client.send(command);
      const command2 = new AdminAddUserToGroupCommand({
        UserPoolId: "eu-central-1_1uHZjaMD6",
        Username: email,
        GroupName: "users",
      });
      const response2 = await client.send(command2);

      enqueueSnackbar("User added successfully", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: 5000,
      });
    })
    .catch((err) => {
      enqueueSnackbar(err?.message || "An error occured", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: 5000,
      });
    });
};
