import React, { useEffect, useState } from "react";
import { IbanElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Snackbar } from "@mui/material";
import { useSnackbar } from "notistack";
import { confirmSubscriptionApi } from "../services/payment";
import IbanForm from "../components/Custom/IbanForm";
import Swal from "sweetalert2";

function Alert(props) {
  return;
  //   <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PaymentSetupForm({
  clientSecret,
  prodId,
  type,
  coupon,
  iterations,
  tax,
  prices,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [snackType, setSnackType] = useState("error");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [line2, setLine2] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState({ id: "DE", name: "Deutschland" });
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentMethodId, setPaymentMethod] = useState(null);
  const clickOpenSnack = (message, type) => {
    setOpenSnack(true);
    setSnackType(type);
    setMessage(message);
    if (type === "success") {
      setTimeout(() => {
        window.close();
      }, 10000);
    }
  };
  useEffect(() => {}, [clientSecret]);
  const handleChange = (e, type) => {
    switch (type) {
      case "email":
        setEmail(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "line2":
        setLine2(e.target.value);
        break;
      case "city":
        setCity(e.target.value);
        break;
      case "zip":
        setZip(e.target.value);
        break;
      case "country":
        setCountry(e);
        break;
      default:
        setCompany(e.target.value);
        break;
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const iban = elements.getElement(IbanElement);
    try {
      if (!paymentMethodId && type === "recurring" && clientSecret) {
        const result = await stripe.confirmSepaDebitSetup(clientSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              name: name,
              email: email,
            },
          },
        });
        if (result.error) {
          // Show error to your customer.
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result.error.message ?? "Es ist ein Fehler aufgetreten",
          });
        } else {
          setPaymentMethod(result.setupIntent.payment_method);
          // confirmSubscription();
        }
      }
      // else if (paymentMethodId && type === "recurring") {
      //   confirmSubscription();
      // }
      else if (!paymentMethodId && type === "one_time") {
        const result = await stripe.confirmSepaDebitPayment(clientSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              name: name,
              email: email,
            },
          },
        });
        if (result.error) {
          // Show error to your customer.
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result.error.message ?? "Es ist ein Fehler aufgetreten",
          });
        } else {
          Swal.fire({
            title: "Your payment has been completed successfully !",
            icon: "success",
          });
          // enqueueSnackbar("Your payment has been completed successfully", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "bottom",
          //     horizontal: "right",
          //   },
          //   autoHideDuration: 5000,
          // });
        }
      }
      setLoading(false);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e?.message ?? "Unexpected error occured ! please try later",
      });

      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    setMessage("");
    setSnackType("");
    setOpenSnack(false);
  };
  const confirmSubscription = () => {
    if (paymentMethodId)
      confirmSubscriptionApi({
        body: {
          name,
          email,
          company,
          paymentMethodId: paymentMethodId,
          productId: prodId,
          iterations,
          coupon,
          address,
          line2,
          zip,
          country,
          city,
          tax,
          prices,
        },
        enqueueSnackbar,
      });
  };
  useEffect(() => {
    confirmSubscription();
  }, [paymentMethodId]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackType}>
          {message}
        </Alert>
      </Snackbar>
      <IbanForm
        loading={loading}
        handleChange={handleChange}
        email={email}
        name={name}
        address={address}
        line2={line2}
        city={city}
        country={country}
        zip={zip}
        coupon={coupon}
        tax={tax}
        prices={prices}
        iterations={iterations}
        company={company}
        onSubmit={handleSubmit}
        disabled={!stripe}
      />
    </>
  );
}
