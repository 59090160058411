export const countries = [
  { name: "Belgien", id: "BE" },
  { name: "Bulgarien", id: "BG" },
  { name: "Dänemark", id: "DK" },
  { name: "Deutschland", id: "DE" },
  { name: "Estland", id: "EE" },
  { name: "Finnland", id: "FI" },
  { name: "Frankreich", id: "FR" },
  { name: "Griechenland", id: "GR" },
  { name: "Irland", id: "IE" },
  { name: "Italien", id: "IT" },
  { name: "Kroatien", id: "HR" },
  { name: "Lettland", id: "LV" },
  { name: "Litauen", id: "LT" },
  { name: "Luxemburg", id: "LU" },
  { name: "Malta", id: "MT" },
  { name: "Niederlande", id: "NL" },
  { name: "Österreich", id: "AT" },
  { name: "Polen", id: "PL" },
  { name: "Portugal", id: "PT" },
  { name: "Rumänien", id: "RO" },
  { name: "Scheweden", id: "SE" },
  { name: "Slowakei", id: "SK" },
  { name: "Slowenien", id: "SI" },
  { name: "Spanien", id: "ES" },
  { name: "Tschechien", id: "CZ" },
  { name: "Ungarn", id: "HU" },
  { name: "Zypern", id: "CY" },
];

export const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
