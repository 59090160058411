import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";

const Quantity = ({ quantity, handleQuantity }) => {
  return (
    <Grid
      style={{
        height: "100%",
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <IconButton
          style={{
            fontWeight: "bold",
            backgroundColor: "#3C96EF",
            color: "#FFFFFF",
            borderRadius: "5px 0px 0px 5px",
            height: "40px",
          }}
          onClick={() => handleQuantity(quantity - 1)}
        >
          -
        </IconButton>
      </Grid>
      <Grid item>
        <input
          name="quantity"
          value={quantity}
          onChange={(e) => handleQuantity(e.target.value)}
          style={{
            width: "50px",
            fontWeight: "bold",
            fontSize: "1.1em",
            textAlign: "center",
            borderRadius: "0px",
            height: "40px",
            boxShadow: "none",
            border: "1px solid #3C96EF",
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          onClick={() => handleQuantity(quantity + 1)}
          style={{
            fontWeight: "bold",
            backgroundColor: "#3C96EF",
            color: "#FFFFFF",
            borderRadius: "0px 5px 5px 0px",
            height: "40px",
          }}
        >
          +
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Quantity;
